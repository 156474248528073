.loadNone {
    display: none !important;
}

.loadPage {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 1000;
    background:none !important;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}