// main container 
.task-edit-view-container {
  display: flex;
  width: 100%;
  gap: 16px;
  margin-top: 5px;
  margin-bottom: 10px;

  // left pannel 
  .left-panel {
    flex: 3;
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;

    h2 {
      font-size: 20px;
      font-weight: bold;
      color: #0052cc;
      margin-bottom: 10px;
    }

    .section {
      margin-top: 10px;
      border-bottom: 1px dashed #dfe1e6;
      padding-top: 12px;

      h3 {
        font-size: 1.1rem;
        font-weight: 600;
        color: #344563;
        // margin-bottom: 8px;  
      }

      p {
        color: #42526e;
        line-height: 1.6;
      }
    }
  }

  // right pannel 
  .right-panel {
    flex: 2;
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;

    h2 {
      font-size: 20px;
      font-weight: bold;
      color: #0052cc;
      margin-bottom: 20px;
    }

    .form-field {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      .form-field-row {
        display: flex;
        align-items: center;

        label {
          margin-right: 8px;
          width: 150px;
          font-size: 0.9rem;
          color: #344563;
          font-weight: 600;
        }

      }

      .p-error {
        margin-top: 4px;
        color: red;
        font-size: 10px;
        text-align: right;
      }
    }

  }
}

.p-invalid {
  border: 1px solid red !important;
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.5) !important;
}

.add-comments-Kanban {
  border-color: #7c3aed;
  color: #7c3aed;
}

.add-comments-Kanban::placeholder {
  color: #7c3aed;
  opacity: 1;
}

/* Style for the send comment icon */
.send-comment-icon {
  font-size: x-large;
  margin: 10px;
  color: #7c3aed;
  cursor: pointer;
  transition: color 0.2s ease;
}

.send-comment-icon:hover {
  color: #5b21b6; /* Darker shade for hover effect */
}

/* Style for the disabled state */
.disabled-icon {
  color: #ccc; /* Gray out the icon */
  cursor: not-allowed; /* Show not-allowed cursor */
}

// kanban image starts
.kanban-view-image-container {
  padding: 16px;
}

.kanban-view-image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 16px;
}

.kanban-view-image-card {
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #dfe1e6;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.kanban-view-image-thumbnail {
  width: 100%;
  margin-top: 0px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.kanban-view-image-footer {
  padding: 5px;
  border-top: 1px solid #dfe1e6;
}

.kanban-view-image-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.kanban-view-image-name {
  font-size: 12px;
  color: #42526E;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.kanban-view-delete-icon {
  cursor: pointer;
  color: #42526E;
  padding: 4px;
  font-size: 14px;
  border-radius: 3px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.kanban-view-delete-icon:hover {
  background-color: #ebecf0;
  color: #DE350B;
}
// kanban image ends

/* Change tooltip background color and text color */
// .p-tooltip .p-tooltip-text {
//   background-color: #0052cc;
//   color: #fff;
//   box-shadow: 1px 1px 20px rgba(#0052cc, 0.3);
// 	font-size: 0.9em;
// }